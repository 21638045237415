import React from 'react'

export default function mobileMenu({navOpen,toggleNav,scrolltoSection}) {
	const onCloseClick = () => {toggleNav()}

	const handleLinkCLick = (link) => scrolltoSection(link)
	
	return (
		<div className={`mobile-menu ${navOpen ? "open" : ""}`}>
			<span className="close" onClick={onCloseClick}></span>
			<ul>
				<li className="home" onClick={()=>{handleLinkCLick(0)}}>
					<span>
						concentrated
							<br />
						creativity
					</span>
					<img src="/images/brain.png" alt="" />
				</li>
				<li className="work" onClick={()=>{handleLinkCLick(1)}}>
					<span>
						how we work
						<br />
						for you
					</span>
					<img src="/images/ear.png" alt="" />
				</li>
				<li className="contact" onClick={()=>{handleLinkCLick(2)}}>
					<span>contact us</span>
					<img src="/images/eye.png" alt="" />
				</li>
			</ul>
		</div>
	)
}