import React,{ useState,useRef } from 'react';

import Header from "./components/header";
import Carousel from "./components/carousel";
import MobileMenu from "./components/mobile-menu";
import './styles/styles.scss';

function App() {
  const [navOpen, setNavOpen] = useState(false);

  const links = [useRef(),useRef(),useRef()]
  const onToggleNav = () => setNavOpen(!navOpen);

  const scrolltoSection = (ref) => {
    const yOffset = -120; 
    const y = links[ref].current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top:y, behavior: "smooth"});

    if(navOpen) {
      onToggleNav()
    }
  };
  return (
    <div className="main-container">
      <div className="border left-border"></div>
      <div className="border right-border"></div>
      <Header toggleNav={onToggleNav} scrolltoSection={scrolltoSection} />
      <MobileMenu navOpen={navOpen} toggleNav={onToggleNav} scrolltoSection={scrolltoSection} />
      <div className="home-section" ref={links[0]}>
        <div className="hero-container">
          <video
            src="/videos/66South_Animation_2.mp4"
            autoPlay
            playsInline
						muted
						loop
          />
          <h2
            className="left-link"
            onClick={() => {
              scrolltoSection(1);
            }}
          >
            HOW WE WORK FOR YOU
          </h2>
          <h2
            className="right-link"
            onClick={() => {
              scrolltoSection(2);
            }}
          >
            CONTACT US
          </h2>
        </div>
        <div className="home-text">
          <h1>CONCENTRATED CREATIVITY</h1>
          <p>
            Potent creative thinking, distilled through focused strategy to
            deliver powerful results.
          </p>
          <p>
            66 South is a full-service healthcare communications agency,
            structured to be nimble and responsive to our clients’ business
            needs.
          </p>
        </div>
      </div>
      <div ref={links[1]}>
        <Carousel />
      </div>
      <div className="contact-section" ref={links[2]}>
        <div className="contact-sction-container">
          <p>
            To see what we’ve done for other healthcare businesses and to
            discuss what we can do for you
          </p>
          <p className="h2">
            <a href="tel:+61280632100" rel="noopener noreferrer">CONTACT +61 2 8063 2100</a>
          </p>
          <p className="h2">
            <a href="mailto:stuart.black@66south.com.au" target="_blank" rel="noopener noreferrer">stuart.black@66south.com.au</a>
          </p>
        </div>
      </div>
      <footer>
        <img src="/images/66-south-footer.png" alt="66 south" onClick={()=>{scrolltoSection(0)}} />
      </footer>
    </div>
  );
}

export default App;
  
