import React,{ useRef,useState,useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.scss';

export default function Carousel() {
  const [textHeight, setTextHeight] = useState(null)
  const equalTextHeight = [useRef(),useRef(),useRef(),useRef()]
  
  useEffect(()=>{
    handleTextHeight()
  })

  const handleTextHeight = () =>{
    let height = 0

    equalTextHeight.forEach((element)=>{
      height = element.current.offsetHeight > height ? element.current.offsetHeight : height
    })

    setTextHeight(height)
  }

  return (
    <div className="carousel-container">
      <Swiper
        spaceBetween={0}
        slidesPerView={'auto'}
        loop={true}
        centeredSlides={true}
      >
        <SwiperSlide>
          <div className="bg-light-blue carousel-item">
            <h2 className="light-blue-contrast">focused team</h2>
            <p ref={equalTextHeight[0]} style={{height: textHeight ? textHeight : 'auto'}}>
              We are nimble and responsive to your service needs. Our approach
              is to work with you to drive outcomes in the most effective and
              efficient way possible.
            </p>
            <img src="/images/focused-team.png" alt="focused team" />
            <span className="aqua-triangle triangle"></span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-light-green carousel-item">
            <h2 className="light-green-contrast">potent creativity</h2>
            <p ref={equalTextHeight[1]} style={{height: textHeight ? textHeight : 'auto'}}>
              We create impactful ideas with cut-through engagement that
              connects with your audience and inspires action.
            </p>
            <img src="/images/potent-creativity.png" alt="potent creativity" />
            <span className="light-blue-triangle triangle"></span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-dark-blue carousel-item">
            <h2 className="dark-blue-contrast">powerful results</h2>
            <p ref={equalTextHeight[2]} style={{height: textHeight ? textHeight : 'auto'}}>
              We deliver on successful outcomes measured by sales increase, rep
              material uptake or better patient response.
            </p>
            <img src="/images/powerful-results.png" alt="powerful results" />
            <span className="light-green-triangle triangle"></span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-aqua carousel-item">
            <h2 className="aqua-contrast">distilled strategy</h2>
            <p ref={equalTextHeight[3]} style={{height: textHeight ? textHeight : 'auto'}}>
              We develop decisive, communication messaging to meet your goals.
            </p>
            <img
              src="/images/distilled-strategy.png"
              alt="distilled strategy"
            />
            <span className="dark-blue-triangle triangle"></span>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
