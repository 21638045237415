import React from "react"

export default function Header({toggleNav, scrolltoSection}) {

	const onNavToggleClick = () => toggleNav()
	
	return (
		<header className="header">
			<img src="/images/66south-logo.png" alt="66south" onClick={()=>{scrolltoSection(0)}}/>
			<h2 className="header-link">CONCENTRATED CREATIVITY</h2>
			<div className="nav-toggle" onClick={onNavToggleClick}>
				<div className="bar"></div>
				<div className="bar"></div>
			</div>
		</header>
	)
}